import config from '@/services/config'
import axios from './axios_interceptors'
import {tokenExpired} from '@/auth/utils'

export default {
  checkProcess() {
    return axios.get(`${config.urlCore}/api/v3/lists/check/process/`, {
      headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
    })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  getTotalList() {
    return axios.get(`${config.urlCore}/api/v3/lists/total-lists`, {
      headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
    })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  getLists () {
    return axios.get(`${config.urlCore}/api/v3/lists/`, {
      headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
    })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  getPreLists () {
    return axios.post(`${config.urlCore}/api/v3/lists/pre-made/`, {
      headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
    })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  createList (formData) {
    return axios.post(`${config.urlCore}/api/v3/lists/`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  updateList (formData, list) {
    return axios.put(`${config.urlCore}/api/v3/lists/${list}/`,
      formData,
      {
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
      })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  deleteList (uuid) {
    return axios.delete(`${config.urlCore}/api/v3/lists/${uuid}/`,
      {
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
      })
      .then(response => response.data.status)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  getInfluencers (uuid) {
    return axios.get(`${config.urlCore}/api/v3/lists/${uuid}/list-influencer/`,
      {
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
      })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  addInfluencer (uuid, formData) {
    return axios.put(`${config.urlCore}/api/v3/lists/${uuid}/add-influencer/`,
      formData,
      {
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
      })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  bulkAddInfluencer (uuid, formData) {
    return axios.post(`${config.urlCore}/api/v3/lists/${uuid}/bulk-add-influencer/`,
      formData,
      {
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
      })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  bulkRemoveInfluencer (uuid, formData) {
    return axios.post(`${config.urlCore}/api/v3/lists/${uuid}/bulk-remove-influencer/`,
      formData,
      {
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
      })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  deleteInfluencer (list, formData) {
    return axios.put(`${config.urlCore}/api/v3/lists/${list}/remove-influencer/${formData.influencer}/`,
      formData,
      {
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
      })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  addListExcel (list,formData) {
    return axios.post(`${config.urlCore}/api/v3/lists/${list}/add-file/`,
      formData,
      {
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
      })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response.data.response
      })
  },
  updateInfluencerList (uuid) {
    return axios.get(`${config.urlCore}/api/v3/lists/update/influencer/${uuid}/`,
      {
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
      })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  orderLists (lists) {
    return axios.post(`${config.urlCore}/api/v3/lists/reorder/lists/`,
      {lists},
      {
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
      })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response.data.response
      })
  },
}
